import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-submit-qualification"
  }, [_c('div', {
    staticClass: "submit-content"
  }, [_c('div', {
    staticClass: "main-wrap"
  }, [_c('div', {
    staticClass: "titleBox"
  }, _vm._l(_vm.activeList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "titleBorder",
      class: {
        activeStyle: _vm.active > index
      }
    }, [_c('div', {
      staticClass: "step"
    }, [_c('p', {
      staticClass: "stepIndex"
    }, [_vm._v(_vm._s(index + 1))]), _c('p', {
      staticClass: "stepTitle"
    }, [_vm._v(_vm._s(item))])])]);
  }), 0), _vm.active == 1 ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "accountInfo"
  }, [_c('span', [_vm._v("*")]), _c('b-field', {
    attrs: {
      "label": "客户类型"
    }
  }, [_c('b-select', {
    attrs: {
      "placeholder": "请选择",
      "expanded": "",
      "required": ""
    },
    model: {
      value: _vm.customType,
      callback: function callback($$v) {
        _vm.customType = $$v;
      },
      expression: "customType"
    }
  }, _vm._l(_vm.customList, function (option) {
    return _c('option', {
      key: option.value,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 0)], 1), _vm.isType ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("请选择客户类型")]) : _vm._e()], 1), _c('div', {
    staticClass: "accountInfo"
  }, [_c('span', [_vm._v("*")]), _c('Cascader', {
    ref: "isValue",
    class: [{
      'is-danger': !_vm.address
    }],
    staticStyle: {
      "float": "left"
    },
    attrs: {
      "options": _vm.options,
      "defaultValue": _vm.address,
      "type": 'userCenter'
    },
    on: {
      "addressdata": _vm.getAddressdata
    }
  })], 1), _c('div', {
    staticClass: "accountInfo"
  }, [_c('span', [_vm._v("*")]), _c('label', {
    staticClass: "label"
  }, [_vm._v("联系人")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.connectName,
      expression: "connectName",
      modifiers: {
        "trim": true
      }
    }],
    class: [{
      'is-danger': _vm.isName
    }, 'input'],
    attrs: {
      "placeholder": "请输入联系人",
      "maxlength": "10"
    },
    domProps: {
      "value": _vm.connectName
    },
    on: {
      "blur": [_vm.blurName, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.connectName = $event.target.value.trim();
      }
    }
  })]), _vm.isName ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("请输入联系人")]) : _vm._e()]), _c('div', {
    staticClass: "accountInfo"
  }, [_c('span', [_vm._v("*")]), _c('label', {
    staticClass: "label"
  }, [_vm._v("联系方式")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.connectPhone,
      expression: "connectPhone",
      modifiers: {
        "trim": true
      }
    }],
    class: [{
      'is-danger': _vm.isPhone
    }, 'input'],
    attrs: {
      "placeholder": "请输入联系电话",
      "maxlength": "11"
    },
    domProps: {
      "value": _vm.connectPhone
    },
    on: {
      "blur": [_vm.blurPhone, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.connectPhone = $event.target.value.trim();
      }, _vm.blurPhone]
    }
  })]), _vm.isPhone ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("输入手机号不正确")]) : _vm._e()]), _c('button', {
    class: ['nextTic', {
      'disBtn': _vm.isPhone || _vm.isType || _vm.isName || !_vm.address || !_vm.connectName || !_vm.connectPhone || !_vm.customType
    }],
    on: {
      "click": function click($event) {
        return _vm.nextStep();
      }
    }
  }, [_vm._v("下一步")])]) : _vm._e(), _vm.active == 2 ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "imgList"
  }, [_vm._m(0), _c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "id": "upload_file",
      "multiple": "",
      "accept": '.png,.jpg,.docx,.pdf,.doc'
    },
    on: {
      "change": function change($event) {
        return _vm.fileChange($event);
      }
    }
  }), _c('div', {
    staticClass: "add-img"
  }, _vm._l(_vm.customList[_vm.customType - 1].imgList, function (item, index) {
    return _c('div', {
      key: index,
      class: item.typeName != '上一年度报告' && item.typeName != '质量保证协议书' ? 'qualication' : 'noPicture'
    }, [item.typeName != '上一年度报告' && item.typeName != '质量保证协议书' ? _c('div', [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.picUrl,
        expression: "item.picUrl"
      }],
      staticClass: "addImg"
    }, [_c('span', {
      staticClass: "delImg",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.delImg(index);
        }
      }
    }, [_vm._v("X")]), item.picUrl ? _c('img', {
      attrs: {
        "src": item.picUrl
      }
    }) : _vm._e()]), !item.picUrl ? _c('div', {
      staticClass: "addImg",
      on: {
        "click": function click($event) {
          return _vm.chooseType(index);
        }
      }
    }, [_vm._m(1, true)]) : _vm._e(), _c('div', {
      staticClass: "imgType"
    }, [item.typeName != 'GSP证书' ? _c('span', {
      staticStyle: {
        "color": "#f00"
      }
    }, [_vm._v("*")]) : _vm._e(), _vm._v(_vm._s(item.typeName) + " ")])]) : _vm._e(), item.typeName == '上一年度报告' || item.typeName == '质量保证协议书' ? _c('div', [_c('div', {
      staticClass: "noPictureCtn"
    }, [_c('label', {
      staticClass: "imgType"
    }, [item.typeName != 'GSP证书' ? _c('span', {
      staticStyle: {
        "color": "#f00"
      }
    }, [_vm._v("*")]) : _vm._e(), _vm._v(_vm._s(item.typeName))]), _c('span', {
      staticClass: "typeName"
    }, [_vm._v(_vm._s(item.picName))]), _c('span', {
      staticClass: "upload",
      on: {
        "click": function click($event) {
          return _vm.chooseType(index);
        }
      }
    }, [_vm._v("上传文件")])]), _c('p', {
      staticClass: "noPicRes"
    }, [_vm._v("上传格式支持word、PDF、JPG、PNG")])]) : _vm._e()]);
  }), 0)]), _c('div', {
    staticClass: "actionBtn"
  }, [_c('button', {
    staticClass: "nextTic befTic",
    on: {
      "click": function click($event) {
        return _vm.befStep();
      }
    }
  }, [_vm._v("上一步")]), _c('button', {
    staticClass: "nextTic",
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("提交")])])]) : _vm._e(), _vm.active == 3 ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "finish"
  }, [_c('img', {
    attrs: {
      "src": require("./images/finish.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v("客户资质材料已提交普健商城平台进行审核，请点击此处"), _c('span', {
    on: {
      "click": function click($event) {
        return _vm.toAuditDetail();
      }
    }
  }, [_vm._v("查看审核进度")])])])]) : _vm._e()])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "customType"
  }, [_vm._v(" 客户类型："), _c('span', [_vm._v("医疗机构")]), _c('p', [_vm._v("资质证照(请上传png、jpg格式图片，每张图片限制在5M)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "add-image",
    attrs: {
      "align": "center"
    }
  }, [_c('p', [_vm._v("+")])]);
}];
export { render, staticRenderFns };